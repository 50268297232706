import { withModifiers as _withModifiers, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-735e94cb"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = { class: "composing" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "as-window",
    onClick: _cache[7] || (_cache[7] = 
//@ts-ignore
(...args) => (_ctx.focus && _ctx.focus(...args))),
    style: _normalizeStyle({
      top: `${_ctx.Y}px`,
      left: `${_ctx.X}px`,
    })
  }, [
    _createElementVNode("div", {
      class: "title-bar",
      onMousedown: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.ondrag && _ctx.ondrag(...args)))
    }, [
      _createElementVNode("i", {
        onClick: _cache[0] || (_cache[0] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.close && _ctx.close(...args)), ["stop"]))
      }),
      _createElementVNode("i", {
        onClick: _cache[1] || (_cache[1] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.minimize && _ctx.minimize(...args)), ["stop"]))
      })
    ], 32),
    _createElementVNode("section", null, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.logs, (line, i) => {
        return (_openBlock(), _createElementBlock("p", {
          key: i,
          innerHTML: line
        }, null, 8, _hoisted_1))
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activelogs, (line, i) => {
        return (_openBlock(), _createElementBlock("p", {
          key: i,
          innerHTML: line
        }, null, 8, _hoisted_2))
      }), 128)),
      _withDirectives(_createElementVNode("p", null, [
        _createElementVNode("span", {
          class: _normalizeClass(["as-input-line", { 'as-input-focus': _ctx.focused }])
        }, [
          _createElementVNode("span", null, _toDisplayString(_ctx.location) + " > ", 1),
          _createElementVNode("span", { innerHTML: _ctx.stylizedInput }, null, 8, _hoisted_3),
          _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.composing), 1),
          _createElementVNode("input", {
            ref: "vinput",
            type: "text",
            onBlur: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.blur && _ctx.blur(...args))),
            onCompositionstart: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.compositionstart && _ctx.compositionstart(...args))),
            onCompositionupdate: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.compositionupdate && _ctx.compositionupdate(...args))),
            onCompositionend: _cache[6] || (_cache[6] = 
//@ts-ignore
(...args) => (_ctx.compositionend && _ctx.compositionend(...args)))
          }, null, 544)
        ], 2)
      ], 512), [
        [_vShow, !_ctx.hold]
      ])
    ])
  ], 4))
}