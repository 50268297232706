
import { defineComponent } from 'vue'

import { welcome, errorCmd, search } from '@/assets/lib/dialogue'

interface TypedChat {
  chat: string
  class: string
}

export default defineComponent({
  name: 'Window',
  data() {
    return {
      X: 200,
      Y: 30,

      location: 'aSearch.io',
      logs: [] as string[],
      activelogs: [] as string[],

      focused: false,
      hold: false,
      isComposing: false,
      nextInput: 0,
      cursorStart: 0,
      cursorEnd: 0,

      runing: null as Promise<boolean> | boolean | null,

      history: [] as string[],
      historyPoint: -1,
      input: '',
      composing: '',
    }
  },
  computed: {
    stylizedInput() {
      // TODO: use RegExp

      const list: TypedChat[] = []
      let input = this.input + ' '

      if (this.cursorStart < 0) {
        input = input.trimEnd()
      }

      let isFirstWord = true
      for (let i = 0; i < input.length; i++) {
        const chat = input[i]
        if (chat === ' ') {
          isFirstWord = false
        }
        const curL = Math.min(this.cursorStart, this.cursorEnd)
        const curR = Math.max(this.cursorStart, this.cursorEnd)
        const selected = curL <= i && i < curR

        const classnames: string[] = []
        if (selected) {
          classnames.push('S')
        } else if (isFirstWord) {
          classnames.push('y')
        }
        if (this.cursorStart === i) {
          classnames.push('C')
        }

        list.push({
          chat,
          class: classnames.join(' '),
        })
      }
      let output = ''
      const tail = list.reduce((prev: TypedChat | null, item) => {
        if (!prev) {
          return item
        } else if (item.class === prev.class) {
          return {
            chat: prev.chat + item.chat,
            class: prev.class,
          }
        } else {
          output += `<span class="${prev.class}">${prev.chat}</span>`
          return item
        }
      }, null)
      if (tail) {
        output += `<span class="${tail.class}">${tail.chat}</span>`
      }
      return output
    },
  },
  created() {
    welcome([], this.logs)
  },
  mounted() {
    this.$el.addEventListener('keydown', this.onkey)
  },
  beforeUnmount() {
    this.$el.removeEventListener('keydown', this.onkey)
  },
  methods: {
    close() {
      this.$emit('close')
    },
    minimize() {
      this.$emit('minimize')
    },
    blur() {
      this.focused = false
    },
    focus() {
      this.focused = true
      const vinput = this.$refs.vinput as HTMLInputElement
      vinput.focus()
    },
    ondrag() {
      let x = this.X
      let y = this.Y
      const { width: w } = (this.$el as HTMLElement).getBoundingClientRect()
      const iw = innerWidth
      const ih = innerHeight
      const onmove = (e: MouseEvent) => {
        x += e.movementX
        y += e.movementY
        this.X = Math.max(80 - w, Math.min(x, iw - 80))
        this.Y = Math.max(0, Math.min(y, ih - 80))
      }
      const onstop = () => {
        document.body.removeEventListener('mousemove', onmove)
        document.body.removeEventListener('mouseup', onstop)
      }
      document.body.addEventListener('mousemove', onmove)
      document.body.addEventListener('mouseup', onstop)
    },
    async run() {
      const input = this.input
      this.cursorStart = -1
      this.cursorEnd = -1
      const cmd = `${this.location} > ${this.stylizedInput}`
      this.logs.push(cmd)
      this.history.unshift(input)

      const args = input.split(' ')

      const runing = (() => {
        this.hold = true
        switch (args[0] || '') {
          case '':
            return true
          case 'reset':
            this.logs = []
            return true
          case 'welcome':
            return welcome(args, this.activelogs)
          case 'search':
            return search(args, this.activelogs)
          default:
            return errorCmd(args, this.activelogs)
        }
      })()
      this.runing = runing

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const result = await runing

      this.logs.push(...this.activelogs)
      this.activelogs = []

      this.hold = false
      this.input = ''
      this.cursorStart = 0
      this.cursorEnd = 0

      this.$nextTick(() => {
        const input = this.$refs.vinput as HTMLInputElement
        input.scrollIntoView()
      })
    },
    delectCurrentSelectedRange() {
      const input = this.input
      const curL = Math.min(this.cursorStart, this.cursorEnd)
      const curR = Math.max(this.cursorStart, this.cursorEnd)

      this.input = input.slice(0, curL) + input.slice(curR)
      this.cursorStart = curL
      this.cursorEnd = curL
    },
    // HANDLE KEY =============================================
    selectHistoryCmd(dir: 1 | -1) {
      const newP = this.historyPoint + dir
      if (newP < 0 || newP >= this.history.length) {
        return
      }

      const cmd = this.history[newP]
      const l = cmd.length

      this.historyPoint = newP
      this.input = cmd
      this.cursorEnd = l
      this.cursorStart = l
    },
    moveCursor(dir: 1 | -1, shiftKey: boolean) {
      const newC = this.cursorStart + dir
      if (newC < 0 || newC > this.input.length) {
        return
      }
      this.cursorStart = newC
      if (!shiftKey) {
        this.cursorEnd = this.cursorStart
      }
    },
    delete() {
      if (this.cursorStart !== this.cursorEnd) {
        // rangeIsSelected
        this.delectCurrentSelectedRange()
      } else if (this.cursorStart > 0) {
        this.input =
          this.input.slice(0, this.cursorStart - 1) +
          this.input.slice(this.cursorStart)
        this.cursorStart--
        this.cursorEnd--
      }
    },
    enter(text: string) {
      this.nextInput = requestAnimationFrame(() => {
        if (this.cursorStart !== this.cursorEnd) {
          // rangeIsSelected
          this.delectCurrentSelectedRange()
        }
        this.input =
          this.input.slice(0, this.cursorStart) +
          text +
          this.input.slice(this.cursorStart)
        const l = text.length
        this.cursorStart += l
        this.cursorEnd += l
      })
    },
    // HANDLE KEY END =========================================
    onkey(e: KeyboardEvent) {
      e.preventDefault()

      if (this.hold) {
        // TODO: ctrl + c
        return
      }

      if (this.isComposing) {
        return
      }
      const vinput = this.$refs.vinput as HTMLInputElement
      vinput.scrollIntoView()

      const key = e.key

      if (key !== 'ArrowUp' && key !== 'ArrowDown') {
        this.historyPoint = -1
      }

      switch (key) {
        case 'Enter':
          return this.run()
        case 'Backspace':
          return this.delete()
        case 'ArrowUp':
        case 'ArrowDown':
          return this.selectHistoryCmd(key === 'ArrowUp' ? 1 : -1)
        case 'ArrowLeft':
        case 'ArrowRight':
          return this.moveCursor(key === 'ArrowRight' ? 1 : -1, e.shiftKey)
      }

      if (key.length === 1) {
        if (e.ctrlKey) {
          // TODO
          return
        }
        this.enter(key)
      }
    },
    compositionstart(e: CompositionEvent) {
      this.isComposing = true
      cancelAnimationFrame(this.nextInput)
      this.composing = e.data
    },
    compositionupdate(e: CompositionEvent) {
      this.composing = e.data
    },
    compositionend(e: CompositionEvent) {
      this.isComposing = false
      const input = e.target as HTMLInputElement
      input.value = ''

      this.composing = ''
      this.enter(e.data)
    },
  },
})
